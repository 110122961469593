/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Button from 'components/atoms/Button/index';
import { colors } from '../../../styles/colors';
import Divider from '../../../assets/images/div.png';
import { Typography } from '../../../styles';

const EventsCardWrapper = styled.div`
  background-color: ${colors.faded_grey};
  .inner_wrapper_res {
    padding: 10px 20px;
  }
  .inside_wrapper_res {
    background-color: ${colors.white};
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 15px;
  }
  .uper_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .lower_wrapper {
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }
  .divider {
    width: 40px;
  }
  .events_title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: ${colors.black};
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }
  .events_city {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    color: ${colors.black};
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }
  .events_date {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }
  .button_section {
    height: 28px;
  }
  .name {
    flex-direction: column;
  }
  @media screen and (min-width: 768px) {
    .inner_wrapper_res {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .inside_wrapper_res {
      width: 49%;
    }
  }
  .button_section_disabled {
    height: 28px;
    background-color: ${colors.light_grey};
    color: ${colors.white};
  }
`;

const EventsCard = ({ data, onButtonClick, isFollowedView = false }) => {
  const { t } = useTranslation();

  return (
    <EventsCardWrapper>
      <div className='inner_wrapper_res'>
        {data?.map(event => {
          return (
            <div key={event.id} className='inside_wrapper_res'>
              <div className='uper_wrapper'>
                <div className='name'>
                  {event.name && (
                    <div className='events_title'>
                      {event.name.length > 27 ? `${event.name.substring(0, 25)}...` : event.name}
                    </div>
                  )}
                  <div className='events_city'>
                    {event.city && event.country
                      ? `${event.city}, ${event.country}`
                      : event.city || event.country || ''}
                  </div>
                </div>
                {isFollowedView ? (
                  <div>
                    <Button className='button_section_disabled' disabled>
                      {t('app.attending')}
                    </Button>
                  </div>
                ) : event.isFollowed ? (
                  <div>
                    <Button className='button_section_disabled' disabled>
                      {t('app.attending')}
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button className='button_section' onClick={() => onButtonClick(event.id)}>
                      {t('app.attend')}
                    </Button>
                  </div>
                )}
              </div>
              <div className='lower_wrapper'>
                <div className='events_date'>
                  {dayjs(event?.startDateTimeUTC).local().format('DD MMM YYYY')}
                </div>
                <div>
                  <img src={Divider} alt='divider' className='divider' />
                </div>
                <div className='events_date'>
                  {dayjs(event?.endDateTimeUTC).local().format('DD MMM YYYY')}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </EventsCardWrapper>
  );
};

export default EventsCard;
