import styled from 'styled-components';
import { notification, Spin } from 'antd';
import { useState } from 'react';
import { Form } from '../atoms';
import FirstStepForm from './sign-up/first-step-form';
import { fetchCallbackApi, updateUserApi, userRegisterApi } from '../../services/settings';
import useApiCall from '../../utils/hooks/useApiCall';
import SecondStepForm from './sign-up/second-step-form';
import SignWrapper from './sign-up/sign-wrapper';
import ThankYou from '../molecules/thank-you';
import JoinCommunity from './join-community';
import useAuth from '../../utils/hooks/useAuth';
import { splitName } from '../../utils/helpers';
import { trackAmplitude } from '../../utils/amplitude';

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  padding: 30px;

  .content_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 750px;
  }
`;

const Home = ({ settings, communityInfo }) => {
  const [firstFormRef] = Form.useForm();
  const [secFormRef] = Form.useForm();
  const [socialData, socialDataLoading, socialDataCall] = useApiCall(fetchCallbackApi);
  const [, updateUserLoading, updateUserCall] = useApiCall(updateUserApi);
  const [, userRegisterLoading, userRegisterCall] = useApiCall(userRegisterApi);
  const [firstStepData, setFirstStepData] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isJoin, setIsJoin] = useState(false);
  const { guestUser } = useAuth();
  const [firstName, lastName] = splitName(guestUser?.name);
  const handleSocialSign = (type, token, code, redirectUri) => {
    socialDataCall(
      {
        type,
        access_token: token,
        access_code: code,
        redirectUri,
      },
      res => {
        if (res?.user?.newUser) {
          firstFormRef.setFieldsValue(res?.user || {});
        } else {
          handleExistingSubmit(res?.user);
        }
      }
    );
  };

  const handleExistingSubmit = values => {
    const updateData = {
      community: {
        communityId: communityInfo?.community?.id,
        inviterId: communityInfo?.user?.id,
      },
      newUser: false,
      firstName: values?.firstName || firstName,
    };
    updateUserCall({ id: values?.id, values: updateData }, () => {
      trackAmplitude('Signup already', 'Completed', 'WebPage');
      setIsSubmitted(true);
    });
  };

  const handleFirstSubmit = values => {
    if (socialData?.user?.id) {
      const updateData = {
        community: {
          communityId: communityInfo?.community?.id,
          inviterId: communityInfo?.user?.id,
        },
        newUser: false,
        countryCode: `+${values?.phoneData?.dialCode || ''}`,
        countryIsoCode: values?.phoneData?.countryCode.toUpperCase(),
        firstName: values?.firstName || firstName,
        lastName: values?.lastName || lastName,
        baseLocation: values?.locationValue?.label,
        currentCity: values?.locationValue?.label,
        currentCountry: values?.locationValue?.country,
        phone: values?.phoneData?.phone.replace(values?.phoneData?.dialCode, ''),
      };
      updateUserCall({ id: socialData?.user?.id, values: updateData }, () => {
        trackAmplitude('Signup social', 'Completed', 'WebPage');
        setIsSubmitted(true);
      });
    } else {
      setFirstStepData(values);
    }
  };

  const handleSecSubmit = values => {
    userRegisterCall(
      {
        ...values,
        community: {
          communityId: communityInfo?.community?.id,
          inviterId: communityInfo?.user?.id,
        },

        newUser: false,
        countryCode: `+${firstStepData?.phoneData?.dialCode || ''}`,
        countryIsoCode: firstStepData?.phoneData?.countryCode.toUpperCase(),
        firstName: firstStepData?.firstName || firstName,
        lastName: firstStepData?.lastName || lastName,
        baseLocation: firstStepData?.locationValue?.label,
        currentCity: firstStepData?.locationValue?.label,
        currentCountry: firstStepData?.locationValue?.country,
        phone: firstStepData?.phoneData?.phone.replace(firstStepData?.phoneData?.dialCode, ''),
      },
      () => {
        trackAmplitude('Signup', 'Completed', 'WebPage');
        setIsSubmitted(true);
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };

  let content = (
    <SignWrapper
      percent={socialData ? 100 : 50}
      setIsJoin={setIsJoin}
      communityInfo={communityInfo}
    >
      <FirstStepForm
        firstName={firstName}
        lastName={lastName}
        form={firstFormRef}
        socialData={socialData}
        settings={settings}
        handleSocialSign={handleSocialSign}
        handleFirstSubmit={handleFirstSubmit}
      />
    </SignWrapper>
  );

  if (firstStepData) {
    content = (
      <SignWrapper percent={100} setIsJoin={setIsJoin} communityInfo={communityInfo}>
        <SecondStepForm
          form={secFormRef}
          email={guestUser?.email}
          handleSecSubmit={handleSecSubmit}
          settings={settings}
          communityInfo={communityInfo}
        />
      </SignWrapper>
    );
  }

  if (isSubmitted) {
    return <ThankYou />;
  }

  if (isJoin) {
    return <JoinCommunity setIsJoin={setIsJoin} />;
  }

  return (
    <Spin spinning={socialDataLoading || updateUserLoading || userRegisterLoading}>
      <HomeWrapper>
        <div className='content_wrapper'>{content}</div>
      </HomeWrapper>
    </Spin>
  );
};

export default Home;
