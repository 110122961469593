import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';
import { Paragraph, Row, Col } from '../atoms';
import { colors } from '../../styles/colors';
import Logo from '../../assets/images/logo-1.png';
import AppStoreImage from '../../assets/images/app-store.png';
import GooglePlayImage from '../../assets/images/google-play.png';

const NotFoundWrapper = styled.div`
  width: 100%;
  background-color: ${colors.main_color};
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .inner_wrapper {
    width: 100%;
    max-width: 800px;

    .logo {
      width: 200px;
      margin-bottom: 40px;
    }

    .market_images_wrapper {
      display: flex;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      img {
        padding: 10px;
      }
    }

    .main_text_wrapper {
      border-right: 1px solid #fff;
    }
  }
  @media screen and (max-width: 992px) {
    padding: 30px;
    .inner_wrapper {
      .main_text_wrapper {
        border-right: none;
        border-bottom: 1px solid #fff;
        padding-bottom: 30px;

        .ant-typography {
          text-align: center;
        }
      }
    }
  }
  @media screen and (max-width: 576px) {
    padding: 30px;
    .inner_wrapper {
      .title {
        font-size: 35px;
        line-height: 50px;
        margin-bottom: 10px;

        &.sec {
          font-size: 28px;
        }
      }
      .subtitle {
        font-size: 16px;
      }
    }
  }
`;

const NotFound = ({ reason }) => {
  return (
    <NotFoundWrapper>
      <div className='inner_wrapper'>
        <img src={Logo} alt='logo' className='logo' />
        <Row mb={60}>
          <Col lg={13} span={24} className='main_text_wrapper'>
            <Paragraph
              fz={64}
              fw={900}
              mb={0}
              color={colors.yellow}
              lh='70px'
              className='title'
              align='start'
            >
              Oops!
            </Paragraph>
            <Paragraph
              fz={45}
              fw={900}
              mb={30}
              color={colors.yellow}
              lh='70px'
              className='title sec'
              align='start'
            >
              {reason === undefined ? (
                <>
                  It seems you’re <br /> using a browser.
                </>
              ) : (
                reason
              )}
            </Paragraph>
            <Paragraph
              fz={24}
              mb={0}
              color={colors.yellow}
              fw={400}
              lh='32px'
              className='subtitle'
              align='start'
            >
              Please open this link on your <br />
              mobile to access the app.
            </Paragraph>
          </Col>
          <Col lg={11} span={24} padding='15px'>
            <Paragraph
              fz={20}
              mb={30}
              color={colors.electric_green}
              fw={400}
              lh='32px'
              className='subtitle'
            >
              Haven't downloaded UBQT yet?
              <br />
              Scan the QR code to get the app!
            </Paragraph>
            <QRCodeSVG
              value='https://l.ead.me/bcleVO'
              includeMargin={true}
              width={200}
              height={200}
            />
          </Col>
        </Row>
        <div className='market_images_wrapper'>
          <a
            href='https://apps.apple.com/ca/app/ubqt/id6447530154'
            target='_blank'
            rel='noreferrer'
          >
            <img src={AppStoreImage} alt='app store' />
          </a>
          <a
            href='https://play.google.com/store/apps/details?id=com.joinubqt.ubqt&pli=1'
            target='_blank'
            rel='noreferrer'
          >
            <img src={GooglePlayImage} alt='google play' />
          </a>
        </div>
      </div>
    </NotFoundWrapper>
  );
};

export default NotFound;
