import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import InvitedUpcoming from 'components/molecules/invited-upcoming';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header from '../components/molecules/header';
import AvatarSection from '../components/molecules/avatar-section';
import EventsCard from '../components/molecules/cards/events-card';
import GetTheApp from '../components/molecules/get-the-app';
import PageLoader from '../components/molecules/page-loader';
// eslint-disable-next-line import/no-named-as-default
import useUserEvent from '../utils/hooks/useUserEvent';
import useAuth from '../utils/hooks/useAuth';
import { followEventApi } from '../services/events';
import useApiCall from '../utils/hooks/useApiCall';
import GetAppQr from '../components/molecules/get-app-qr';
import { colors } from '../styles/colors';
import About from '../components/molecules/about';
import NotFound from '../components/molecules/not-found';
import { trackAmplitude } from '../utils/amplitude';

const EventsWrapper = styled.div`
  @media (min-width: 576px) {
    padding: 0px;
  }

  @media (min-width: 768px) {
    padding: 0px 50px;
  }

  @media (min-width: 992px) {
    padding: 0px 50px;
  }

  @media (min-width: 1200px) {
    padding: 0px 50px;
  }

  @media (min-width: 1400px) {
    padding: 0px 50px;
  }
  .mobile_view {
    display: block;
  }
  .desktop_view {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    .mobile_view {
      display: none;
    }

    .desktop_view {
      display: flex;
      justify-content: space-between;
      background-color: ${colors.faded_grey};
    }
  }
  @media (min-width: 1024px) {
    .left-section {
      margin: 0 2%;
    }
    .right-section {
      margin-right: 10%;
    }

    .left-section {
      flex: 0 0 60%;
      max-width: 60%;
      margin-top: 30px;
    }

    .right-section {
      flex: 0 0 20%;
      max-width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
    }
  }
`;

const Events = () => {
  const navigate = useNavigate();
  const {
    stateLoading,
    user,
    events: initialEvents,
    expiryDate,
    userSlug,
    token,
    followedEvents: initialFollowedEvents,
    errors,
  } = useUserEvent();
  const [, followEventLoading, followEventCall] = useApiCall(followEventApi);
  const { guestUser } = useAuth();
  const { t } = useTranslation();

  const [events, setEvents] = useState(initialEvents);
  const [followedEvents, setFollowedEvents] = useState(initialFollowedEvents);

  useEffect(() => {
    setEvents(initialEvents);
    setFollowedEvents(initialFollowedEvents);
  }, [initialEvents, initialFollowedEvents]);

  useEffect(() => {
    trackAmplitude('Events', 'Open', 'WebPage');
  }, []);

  const followSingleEvent = id => {
    const followEventObj = {
      guestEmail: guestUser?.email,
      eventId: id,
    };
    followEventCall(
      followEventObj,
      () => {
        notification.success({ message: 'Event joined successfully' });
        trackAmplitude('Event', 'Followed', 'WebPage');
        const updatedFollowedEvents = [...followedEvents, events.find(event => event.id === id)];
        setEvents(events.filter(event => event.id !== id));
        setFollowedEvents(updatedFollowedEvents);
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };

  const allEvents = useMemo(() => {
    const newEvents = events.map(event => {
      return { ...event, isFollowed: false };
    });
    const newFollowedEvents = followedEvents.map(event => {
      return { ...event, isFollowed: true };
    });
    return [...newEvents, ...newFollowedEvents];
  }, [events, followedEvents]);

  if (stateLoading || followEventLoading) {
    return <PageLoader />;
  }

  return (
    <EventsWrapper>
      <div className='main_wrapper'>
        <Header />
        {(errors?.message || !user) && <NotFound reason={errors.message} />}
        {!errors?.message && user?.fullName && (
          <>
            <InvitedUpcoming
              description={t('app.inviteEventMessage', { userName: user?.fullName })}
            />
            <AvatarSection
              avatarUrl={user?.avatar?.url}
              name={user?.fullName}
              expiryDate={expiryDate}
            />
            <div className='mobile_view'>
              <EventsCard
                data={events}
                onButtonClick={eventId => {
                  if (guestUser) {
                    followSingleEvent(eventId);
                  } else {
                    navigate(`${`/event-detail`}/${eventId}?token=${token}&userSlug=${userSlug}`);
                  }
                }}
              />
              <GetTheApp name={user?.fullName} />
              <EventsCard data={followedEvents} isFollowedView={true} />
            </div>

            <div className='desktop_view'>
              <div className='left-section'>
                <EventsCard
                  data={allEvents}
                  onButtonClick={eventId => {
                    if (guestUser) {
                      followSingleEvent(eventId);
                    } else {
                      navigate(`${`/event-detail`}/${eventId}?token=${token}&userSlug=${userSlug}`);
                    }
                  }}
                />
              </div>
              <div className='right-section'>
                <GetAppQr name={user?.fullName} />
              </div>
            </div>
          </>
        )}
        <About />
      </div>
    </EventsWrapper>
  );
};

export default Events;
