import { Progress } from 'antd';
import styled from 'styled-components';
import Logo from '../../../assets/images/logo.png';
import { Paragraph } from '../../atoms';
import { colors } from '../../../styles/colors';

export const SignWrapperComp = styled.div`
  text-align: center;
  .logo_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }

  .progress {
    margin-bottom: 30px;
  }

  .divider {
    margin: 15px 0;

    .ant-divider-inner-text {
      color: ${colors.grey};
      font-size: 14px;
      font-weight: 400;
    }

    &:before,
    &:after {
      border-block-start-color: #e6e6e6;
    }
  }

  .social_wrapper {
    .google_icon {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }

    .fb_wrapper {
      height: 28px;

      button {
        background-color: #fff;
        width: fit-content;
        height: 28px;
        padding: 0;
        border-radius: 50%;
        border: none;

        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .logo {
    width: 200px;
  }

  @media screen and (max-width: 576px) {
    .logo_wrapper {
      margin-bottom: 30px;
    }
    .title {
      font-size: 32px;
      line-height: 40px;
    }
    .subtitle {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
`;

const SignWrapper = ({ children, percent = 50, setIsJoin, communityInfo }) => {
  return (
    <SignWrapperComp>
      <div className='logo_wrapper'>
        <img src={Logo} alt='logo' className='logo' />
      </div>
      {communityInfo ? (
        <>
          <Paragraph fz={64} fw={900} mb={30} color={colors.main_color} className='title' lh='62px'>
            Welcome to <br />
            {communityInfo?.community?.name || ''}
          </Paragraph>
          <Paragraph fz={21} mb={20} color={colors.grey} fw={400} className='subtitle' lh='25px'>
            <span style={{ color: colors.dark_green, fontWeight: 700 }}>
              {communityInfo?.user?.fullName || ''}
            </span>{' '}
            has invited you to join{' '}
            <span style={{ color: colors.dark_green, fontWeight: 700 }}>
              {communityInfo?.community?.name || ''}
            </span>{' '}
            community.
          </Paragraph>
          <Paragraph fz={21} mb={30} color={colors.grey} fw={400} className='subtitle' lh='25px'>
            Reconnect with your tribe, in real life. Wherever you are in the world, <br /> you’ll
            never miss a chance to catch up.
          </Paragraph>
        </>
      ) : null}

      <Paragraph fz={32} fw={700} color={colors.main_color} mb={5} align='start'>
        Sign up
      </Paragraph>
      <Progress
        percent={percent}
        showInfo={false}
        strokeColor={colors.electric_green}
        className='progress'
      />
      {children}
      {communityInfo ? (
        <Paragraph color={colors.main_color} fw={400} margin='25px 0 15px 0'>
          Already signed up?{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => setIsJoin(true)}
          >
            Join the community
          </span>
        </Paragraph>
      ) : null}
    </SignWrapperComp>
  );
};

export default SignWrapper;
