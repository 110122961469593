import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { notification } from 'antd';

import useApiCall from './useApiCall';
import useAuth from './useAuth';
import { fetchEventInfoApi } from '../../services/events';

export const useUserEvent = () => {
  const [state, stateLoading, stateCall] = useApiCall(fetchEventInfoApi);
  const { events, user, expiryDate, followedEvents } = state || {
    events: [],
    user: null,
    expiryDate: null,
    followedEvents: [],
  };
  const [errors, setErrors] = useState({});

  const { guestUser, isAuthInitialized } = useAuth();
  const [userSlug, setUserSlug] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [searchParams] = useSearchParams();
  const time = searchParams.get('token');
  const guest = searchParams.get('guestEmail');
  const params = useParams();

  useEffect(() => {
    if (!isAuthInitialized) return;

    setUserSlug(params?.name);
    setTimestamp(time);
    const guestEmailObj = {
      guestEmail: guest || guestUser?.email || null,
      token: time,
      userSlug: params?.name,
    };

    stateCall(
      guestEmailObj,
      () => {
        notification.success({ message: 'Events data fetched successfully' });
      },
      error => {
        setErrors(error);
        notification.error({ message: error.message });
      }
    );
  }, [guestUser?.email, isAuthInitialized]);

  return {
    user,
    events,
    expiryDate,
    stateLoading,
    userSlug,
    token: timestamp,
    followedEvents,
    errors,
  };
};

export default useUserEvent;
