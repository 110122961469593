import styled from 'styled-components';
import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../styles/colors';

import logo from '../../assets/images/logo-1.png';

const GetAppQrWrapper = styled.div`
  .get_app_wrapper {
    padding: 20px;
    background-color: ${colors.main_color};
  }

  .logo {
    width: 100px;
    margin-left: -50px;
  }

  .market_images_wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
  }

  .app_store {
    width: 90%;
  }

  .title_plan {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    color: ${colors.yellow};
    margin-bottom: 15px;
  }

  .subtitle {
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    color: ${colors.white};
    margin-bottom: 15px;
  }

  .qr {
    width: 150px;
  }

  .qr_wrapper {
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  .heading_wrapper_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .plan_title {
    font-size: 35px;
    font-weight: 700;
    line-height: 40px;
    color: ${colors.yellow};
    margin-bottom: 15px;
    width: 264px;
  }

  .get_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: ${colors.white};
    text-align: center;
    margin-top: 15px;
  }

  .reconnect {
    font-size: 20px;
    font-weight: 700;
    line-height: 0px;
    color: ${colors.yellow};
    text-align: center;
  }

  .m-b {
    margin-bottom: 20px;
  }
`;

const GetAppQr = ({ name }) => {
  const { t } = useTranslation();

  return (
    <GetAppQrWrapper>
      <div className='get_app_wrapper'>
        <div className='heading_wrapper_img'>
          <div className='plan_title'>
            {t('app.wantToPlan')}
            <br />
            {t('app.catchup', { name })}
          </div>
          <div>
            <img src={logo} alt='app store' className='logo' />
          </div>
        </div>

        <div className='qr_wrapper'>
          <QRCodeSVG
            value={'https://joinubqt.com/'}
            size={178}
            bgColor={`${colors.white}`}
            fgColor={`${colors.black}`}
            level={'L'}
            includeMargin={true}
          />
        </div>
        <div className='m-b'>
          <div className='get_title'>{t('app.getTitle')}</div>
          <br />
          <div className='reconnect'>{t('app.reconnect')}</div>
        </div>
      </div>
    </GetAppQrWrapper>
  );
};

export default GetAppQr;
